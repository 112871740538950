import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Projects from '../components/Projects';
import SEO from '../components/SEO';

const ProjectsPage = ({
  data: {
    allStrapiProjects: { nodes: projects },
  },
}) => {
  return (
    <Layout>
      <SEO
        title="Projects | Alex Polcastro"
        description="Alex Policastro's design & development projects."
        url="/projects"
      />

      <section
        className="project-page"
        role="main"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-delay="100"
        data-sal-easing="easeInOutSine"
      >
        <Projects
          projects={projects}
          title="Projects"
          h1
          showFilter
        />
      </section>
    </Layout>
  );
};

ProjectsPage.propTypes = {
  data: PropTypes.shape({
    allStrapiProjects: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape),
    }),
  }),
};

ProjectsPage.defaultProps = {
  data: [],
};

export default ProjectsPage;

export const query = graphql`
  {
    allStrapiProjects {
      nodes {
        slug
        id
        description
        url
        clientUrl
        title
        order
        category
        image2Text
        image3Text
        image4Text
        image5Text
        image6Text
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
  }
`;
